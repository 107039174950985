@import url('https://fonts.googleapis.com/css?family=Lato');

@font-face {
    font-family: 'Bodoni';
    src: local('Bodoni'),
         url('./fonts/BodoniFLF-Roman.ttf') format('truetype');
}

.rsvp-iframe {
	background-color: transparent !important;
}

.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: right;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.sk-spinner {
	margin-top:25%;
}

.h-full {
	height: 95% !important;
}

.greenText {
	color: rgb(156, 175, 136);
	font-size: 12px;
	font-weight: 400;
	line-height: 1.5;
    letter-spacing: 0.00938em;
}

.whiteText {
	color: white;
	font-size: 12px;
	font-weight: 400;
	line-height: 1.5;
    letter-spacing: 0.00938em;
}

$screen-background: #121010;

.MuiButtonGroup-firstButton {
	border-color: #FFFFFF !important;
	color: #FFFFFF !important;
	font-size: 12px !important;
}

.MuiButtonGroup-middleButton {
	border-color: #FFFFFF !important;
	color: #FFFFFF !important;
	font-size: 12px !important;
}

.MuiButtonGroup-lastButton {
	border-color: #FFFFFF !important;
	color: #FFFFFF !important;
	font-size: 12px !important;
}

#tabContents {
	overflow-y: scroll; 
	height: 40vh;
	width: 100%;
	position: center;
	text-align: justify;
    text-justify: inter-word;
	
    /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
    -ms-overflow-style: none;  /* Internet Explorer and Edge */
    scrollbar-width: none;  /* Firefox */

    /* Hide the scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
       display: none;
    }
}

#carousel {
	padding-top: 5%;
	padding-right: 30%;
	background-color:rgba(0, 0, 0, 0) !important;
	& > div {
		background-color:rgba(0, 0, 0, 0) !important;
	}
}

.rsis-container {
    background-size: contain !important;
	background-color:rgba(0, 0, 0, 0) !important;
}


  #faqDetails {
	padding-left: 25%;
	padding-right: 25%;
}

#accomodationDetails {
	padding-left: 25%;
	padding-right: 25%;
}	

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (orientation: portrait)  {

	h1 {
		letter-spacing: 2px;
		text-align: center;
		text-transform: uppercase;
		font-size: 52px;
		font-family: "Bodoni";
	}

	.main {
		width: 100%;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	.content {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 3;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	.title {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 3;
		padding-top: 20%;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.6);
		overflow-y: hidden;
		overflow-x: hidden;
	}

	@mixin flexCenter {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@mixin pseudo {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: " ";
	}

	.screen {
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
		@include flexCenter;

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/86186/crt.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			content: "";
		}
		&::before {
			@include pseudo;
			background: linear-gradient(
					transparentize($screen-background, 1) 50%,
					transparentize(darken($screen-background, 10), 0.75) 50%
				),
				linear-gradient(
					90deg,
					transparentize(#ff0000, 0.94),
					transparentize(#00ff00, 0.98),
					transparentize(#0000ff, 0.94)
				);
			z-index: 2;
			background-size: 100% 2px, 3px 100%;
			pointer-events: none;
		}
	}

	* {
		box-sizing: border-box;
	}

	.countdown-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		z-index: 4;
	}

	.countdown-item {
		color: white;
		font-size: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		line-height: 30px;
		margin: 10px;
		padding-top: 10px;
		position: relative;
		width: 100px;
		height: 100px;
	}

	.countdown-item span {
		color: white;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
	}

	.countdown-svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100px;
		height: 100px;
		& > * {
		stroke: #9CAF88;
		}
	}

	#faqDetails {
		padding-left: 25%;
		padding-right: 25%;
	}
	
	#accomodationDetails {
		padding-left: 25%;
		padding-right: 25%;
	}	
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (orientation: landscape)  {

	h1 {
		letter-spacing: 2px;
		text-align: center;
		text-transform: uppercase;
		font-size: 32px;
		font-family: "Bodoni";
	}

	.main {
		height: 100%;
		width: 100%;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	video {
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
	}

	.content {
		position: absolute;
		width: 100%;
		top: 15%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 3;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	.title {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 3;
		padding-top: 20%;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.6);
		overflow-y: hidden;
		overflow-x: hidden;
	}

	@mixin flexCenter {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@mixin pseudo {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: " ";
	}

	.screen {
		left: 0;
		position: relative;
		top: 0;
		width: 100%;
		z-index: 1;
		@include flexCenter;

		&::after {
			position: relative;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/86186/crt.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			content: "";
		}
		&::before {
			@include pseudo;
			background: linear-gradient(
					transparentize($screen-background, 1) 50%,
					transparentize(darken($screen-background, 10), 0.75) 50%
				),
				linear-gradient(
					90deg,
					transparentize(#ff0000, 0.94),
					transparentize(#00ff00, 0.98),
					transparentize(#0000ff, 0.94)
				);
			z-index: 2;
			background-size: 100% 2px, 3px 100%;
			pointer-events: none;
		}
	}

	* {
		box-sizing: border-box;
	}

	.countdown-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		z-index: 4;
	}

	.countdown-item {
		color: white;
		font-size: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		line-height: 30px;
		margin: 10px;
		padding-top: 10px;
		position: relative;
		width: 100px;
		height: 100px;
	}

	.countdown-item span {
		color: white;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
	}

	.countdown-svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100px;
		height: 100px;
		& > * {
		stroke: #9CAF88;
		}
	}

	#faqDetails {
		padding-left: 25%;
		padding-right: 25%;
	}
	
	#accomodationDetails {
		padding-left: 25%;
		padding-right: 25%;
	}
	
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px){

	h1 {
		letter-spacing: 2px;
		text-align: center;
		text-transform: uppercase;
		font-size: 32px;
		font-family: "Bodoni";
	}

	.main {
		width: 100%;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	.content {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 3;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	.title {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 3;
		padding-top: 20%;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.6);
		overflow-y: hidden;
		overflow-x: hidden;
	}

	@mixin flexCenter {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@mixin pseudo {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: " ";
	}

	.screen {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
		@include flexCenter;

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/86186/crt.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			content: "";
		}
		&::before {
			@include pseudo;
			background: linear-gradient(
					transparentize($screen-background, 1) 50%,
					transparentize(darken($screen-background, 10), 0.75) 50%
				),
				linear-gradient(
					90deg,
					transparentize(#ff0000, 0.94),
					transparentize(#00ff00, 0.98),
					transparentize(#0000ff, 0.94)
				);
			z-index: 2;
			background-size: 100% 2px, 3px 100%;
			pointer-events: none;
		}
	}

	* {
		box-sizing: border-box;
	}

	.countdown-wrapper {
		padding-top: 10%;
		padding-bottom: 10%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		z-index: 4;
	}

	.countdown-item {
		color: white;
		font-size: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 10px;
		line-height: 30px;
		padding-top: 10px;
		position: relative;
		width: 70px;
		height: 40px;
	}

	.countdown-item span {
		color: white;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
	}

	.countdown-svg {
		position: absolute;
	    padding-left: 200px;
		top: 2;
		padding-top: 50px;
	
		& > * {
		stroke: none;
		}
	}

	#faqDetails {
		padding-left: 25%;
		padding-right: 25%;
	}
	
	#accomodationDetails {
		padding-left: 25%;
		padding-right: 25%;
	}
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	h1 {
		letter-spacing: 2px;
		text-align: center;
		text-transform: uppercase;
		font-size: 52px;
		font-family: "Bodoni";
	}

	.main {
		width: 100%;
		height: 100vh;
	}

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.content {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 3;
	}

	.title {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 3;
		padding-top: 20%;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.6)
	}

	@mixin flexCenter {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@mixin pseudo {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: " ";
	}

	.screen {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
		@include flexCenter;

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/86186/crt.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			content: "";
		}
		&::before {
			@include pseudo;
			background: linear-gradient(
					transparentize($screen-background, 1) 50%,
					transparentize(darken($screen-background, 10), 0.75) 50%
				),
				linear-gradient(
					90deg,
					transparentize(#ff0000, 0.94),
					transparentize(#00ff00, 0.98),
					transparentize(#0000ff, 0.94)
				);
			z-index: 2;
			background-size: 100% 2px, 3px 100%;
			pointer-events: none;
		}
	}

	* {
		box-sizing: border-box;
	}

	.countdown-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		z-index: 4;
	}

	.countdown-item {
		color: white;
		font-size: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		line-height: 30px;
		margin: 10px;
		padding-top: 10px;
		position: relative;
		width: 100px;
		height: 100px;
	}

	.countdown-item span {
		color: white;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
	}

	.countdown-svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100px;
		height: 100px;
		& > * {
		stroke: #9CAF88;
		}
	}

	#faqDetails {
		padding-left: 25%;
		padding-right: 25%;
	}
	
	#accomodationDetails {
		padding-left: 25%;
		padding-right: 25%;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

	h1 {
		letter-spacing: 2px;
		text-align: center;
		text-transform: uppercase;
		font-size: 52px;
		font-family: "Bodoni";
	}

	.main {
		width: 100%;
		height: 100vh;
	}

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.content {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 3;
	}

	.title {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 3;
		padding-top: 20%;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.6)
	}

	@mixin flexCenter {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@mixin pseudo {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: " ";
	}

	.screen {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
		@include flexCenter;

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/86186/crt.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			content: "";
		}
		&::before {
			@include pseudo;
			background: linear-gradient(
					transparentize($screen-background, 1) 50%,
					transparentize(darken($screen-background, 10), 0.75) 50%
				),
				linear-gradient(
					90deg,
					transparentize(#ff0000, 0.94),
					transparentize(#00ff00, 0.98),
					transparentize(#0000ff, 0.94)
				);
			z-index: 2;
			background-size: 100% 2px, 3px 100%;
			pointer-events: none;
		}
	}

	* {
		box-sizing: border-box;
	}

	.countdown-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		z-index: 4;
	}

	.countdown-item {
		color: white;
		font-size: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		line-height: 30px;
		margin: 10px;
		padding-top: 10px;
		position: relative;
		width: 100px;
		height: 100px;
	}

	.countdown-item span {
		color: white;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
	}

	.countdown-svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100px;
		height: 100px;
		& > * {
		stroke: #9CAF88;
		}
	}

	#faqDetails {
		padding-left: 25%;
		padding-right: 25%;
	}
	
	#accomodationDetails {
		padding-left: 25%;
		padding-right: 25%;
	}
}

@media only screen and (min-width: 1200px) {
/* Extra large devices (large laptops and desktops, 1200px and up) */

	h1 {
		letter-spacing: 2px;
		text-align: center;
		text-transform: uppercase;
		font-size: 46px;
		font-family: "Bodoni";
	}	

	.main {
		width: 100%;
		height: 100vh;
	}

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.content {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 3;
	}

	.title {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 3;
		padding-top: 20%;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.6)
	}

	@mixin flexCenter {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@mixin pseudo {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: " ";
	}

	.screen {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
		@include flexCenter;

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/86186/crt.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			content: "";
		}
		&::before {
			@include pseudo;
			background: linear-gradient(
					transparentize($screen-background, 1) 50%,
					transparentize(darken($screen-background, 10), 0.75) 50%
				),
				linear-gradient(
					90deg,
					transparentize(#ff0000, 0.94),
					transparentize(#00ff00, 0.98),
					transparentize(#0000ff, 0.94)
				);
			z-index: 2;
			background-size: 100% 2px, 3px 100%;
			pointer-events: none;
		}
	}

	* {
		box-sizing: border-box;
	}

	.countdown-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		z-index: 4;
	}

	.countdown-item {
		color: white;
		font-size: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		line-height: 30px;
		margin: 10px;
		padding-top: 10px;
		position: relative;
		width: 100px;
		height: 100px;
	}

	.countdown-item span {
		color: white;
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
	}

	.countdown-svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100px;
		height: 100px;
		& > * {
		stroke: #9CAF88;
		}
	}
	
	#faqDetails {
		padding-left: 0%;
		padding-right: 0%;
	}
	
	#accomodationDetails {
		padding-left: 0%;
		padding-right: 0%;
	}
}